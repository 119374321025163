<template>
	<l-map ref="map" :zoom="zoom" :center="center" :options="mapOptions" @update:center="centerUpdate" @update:zoom="zoomUpdate">
		<l-tile-layer :url="url" :attribution="attribution" />
		<l-marker :lat-lng.sync="marker" :draggable="draggable"></l-marker>
	</l-map>
</template>

<script>
import Vue from "vue";
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import L, { marker } from "leaflet";

export default Vue.extend({
	name: "LeafletMap",

	components: { LMap, LTileLayer, LMarker },

	props: {
		latLng: {
			type: Object
		},
		draggable: {
			type: Boolean
		}
	},

	watch: {
		latLng: {
			deep: true,
			immediate: true,
			handler(latLng) {
				this.center = L.latLng(latLng.lat, latLng.lng);
				this.marker = L.latLng(latLng.lat, latLng.lng);
			}
		},
		marker: {
			deep: true,
			immediate: true,
			handler(newPos, oldPos) {
				if (newPos.lat != oldPos.lat && newPos.lng != oldPos.lng) {
					this.$emit("update:latLng", newPos);
				}
			}
		}
	},

	data: function() {
		return {
			zoom: 6,
			center: L.latLng(-27.728871, 24.789104),
			url: "http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
			attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
			marker: L.latLng(-27.728871, 24.789104),
			currentZoom: 6,
			currentCenter: L.latLng(-27.728871, 24.789104),
			mapOptions: {
				zoomSnap: 0.5
			}
		};
	},

	mounted: function() {
		this.setDefaultIcon();
		// @ts-ignore
		this.$refs.map.mapObject._onResize();
	},

	methods: {
		setDefaultIcon() {
			delete L.Icon.Default.prototype._getIconUrl;
			L.Icon.Default.mergeOptions({
				iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
				iconUrl: require("@/assets/ic_Map Marker.png"),
				shadowUrl: require("leaflet/dist/images/marker-shadow.png")
			});
		},
		onMapClick(clickEvent) {
			this.$emit("update:latLng", clickEvent.latlng);
		},
		zoomUpdate(zoom) {
			this.currentZoom = zoom;
		},
		centerUpdate(center) {
			this.currentCenter = center;
		}
	}
});
</script>

<style></style>
